.banking-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background: #fafafa;
  }
  
  .banking-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .banking-icon {
    font-size: 3rem;
    color: #000440;
    margin-bottom: 15px;
    animation: bounce 2s infinite;
  }
  
  .banking-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin: 30px 0;
  }
  
  .banking-img {
    max-height: 300px;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .banking-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .banking-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-icon {
    font-size: 2.5rem;
    color: #000440;
    margin-bottom: 15px;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  .banking-footer {
    text-align: center;
    margin-top: 30px;
  }
  
  .banking-title {
    font-size: 2rem;
    font-weight: 700;
    color: #000440;
  }
  
  .banking-subtitle {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
  }
  
  .banking-content {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .banking-section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 20px;
  }
  
  .banking-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .banking-list li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .banking-introduction {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .banking-text {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .banking-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
  }
  
  .banking-list li {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  .banking-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
  }
  
  .benefit-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.33% - 20px);
    min-width: 280px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .benefit-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 10px;
  }
  
  .benefit-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .benefit-card {
      max-width: 45%; 
    }
  }
  
  @media (max-width: 768px) {
    .benefit-card {
      max-width: 90%; 
    }
  
    .section-title {
      font-size: 1.2rem;
    }
  
    .banking-text {
      font-size: 0.9rem;
    }
  }
  
  /* Expertise Section */
.banking-expertise-section {
  padding: 40px 20px;
  background: #f9f9f9;
  text-align: center;
}

.expertise-section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #000440;
  margin-bottom: 20px;
}

.expertise-intro-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

/* Timeline Styles */
.expertise-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  padding-left: 20px;
}

.expertise-timeline::before {
  content: '';
  position: absolute;
  left: 50px;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #004aad;
}

.expertise-timeline-item {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}

.expertise-icon {
  background: #000440;
  color: #fff;
  font-size: 2rem;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 1;
}

.expertise-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
  flex: 1;
}

.expertise-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000440;
  margin-bottom: 10px;
}

.expertise-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .expertise-timeline {
    padding-left: 0;
  }

  .expertise-timeline::before {
    display: none;
  }

  .expertise-timeline-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .expertise-icon {
    margin-bottom: 10px;
  }

  .expertise-content {
    text-align: center;
  }
}
