.cta-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    margin-top: 50px;
  }
  

 
  
  .cta-box {
    background-color: #000433; /* Navy blue */
    color: #ffffff; /* White text */
    text-align: center;
    padding: 30px 20px;
    border-radius: 20px; /* Rounded corners */
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .cta-text {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #f1f6e1; /* Beige color */
    color: #000433; /* Navy text */
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 10px; /* Rounded button */
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e4ecf9; /* Lighter shade on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  @media (max-width: 768px) {
    .cta-text {
      font-size: 1rem; /* Adjust text size for smaller screens */
    }
  
    .cta-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  