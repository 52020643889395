.definition-container {
  background-color: #ffffff;
  padding: 40px; 
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  max-width: 100%; 
  margin: 20px auto;
  margin-bottom: 100px;
}

.definition-header {
  margin-bottom: 30px;
}

.definition-title {
  background-color: #000433;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  padding: 15px 30px; 
  font-size: 1.4rem; 
  font-weight: bold;
  cursor: default !important;
  
}

.definition-content {
  display: flex;
  align-items: flex-start;
  gap: 30px; 
  flex-wrap: wrap;
}

.definition-image {
  flex: 1; /* Allows the image to take a proportional width */
  max-width: 55%; /* Restricts image size to a maximum percentage */
  height: auto; /* Keeps the aspect ratio intact */
  height: 250px;
}

.definition-text {
  flex: 2;
  color: #000433;
  font-size: 1.4rem; 
  line-height: 1.6;
  width: 100%;
  
}

.definition-text p {
  padding: 8px 0; 
}

.definition-text strong {
  font-weight: bold;
}

@media (max-width: 1480px) {

    .definition-container {
        padding: 20px;
        margin-bottom: 100px;
      }
      .definition-image {
        width: 200px; 
      }
    
      .definition-text {
        font-size: 1.2rem; 
      }
    
      .definition-title{
        font-size: 0.8rem;
      }
}



@media (max-width: 768px) {
  .definition-container {
    margin-bottom: 100px;
  }
  .definition-image {
    margin-left: -50px;
  }

  .definition-text {
    font-size: 0.6rem; 
  }

  .definition-title{
    font-size: 0.6rem;
    margin-left: -50px;
  }
}


