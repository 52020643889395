.erp-container {
    padding: 50px;
    background-color: #f1f6fc;
  }
  
  .erp-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  
  .category-btn {
    background: #000440;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 1.5rem;
    cursor: default;
  }
  
  .erp-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .erp-intro {
    margin-bottom: 30px;
  }
  
  .erp-intro p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .highlight {
    font-weight: bold;
    color: #004aad;
  }
  
  .erp-section,
  .applications-section {
    margin-top: 30px;
  }
  
  .erp-card,
  .applications-card {
    background: linear-gradient(135deg, #000440, #004aad);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.3rem;
    border-radius: 30px;
    margin-bottom: 20px;
    cursor: default;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .technologies {
    display: flex;
    gap: 15px;
    margin: 20px 0;
    justify-content: center;
  }
  
  .tech-btn {
    background-color: #f1f6e1;
    color: #004aad;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 1.2rem;
    cursor: default;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .tech-btn:hover {
    color: #000440;
    transform: translateY(-5px);

    
  }
  
  .applications-section p,
  .erp-section p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-left: 50px;
  }


  .img-erp {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
  }
  

  .img-div{
    width: 100%;
    text-align: center;
  }
@media (max-width: 1028px) {

  .erp-container{
    padding: 10px;
  }

  .erp-header{
    margin-top: 30px;
  }

  .erp-content p{
    font-size: 0.7rem;
  }

  .category-btn{
    font-size: 1rem;
  }

  .erp-card, .applications-card {
    font-size: 0.9rem;
  }

  .tech-btn{
    font-size: 0.8rem;
  }


}

@media (max-width: 768px){

  .erp-container{
    padding: 10px;
  }

  .erp-header{
    margin-top: 30px;
  }

  .erp-content p{
    font-size: 0.7rem;
  }

  .category-btn{
    font-size: 1rem;
  }

  .erp-card, .applications-card {
    font-size: 0.9rem;
  }

  .tech-btn{
    font-size: 0.8rem;
  }

  .img-div img{
    width: 100%;
  }
}