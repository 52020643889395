@import '../css/App.css';


.security-practice-container {
    padding: 40px;
    background-color: #f4f6fc;

    
}

.security-title {
    text-align: center;
    color: var(--purple);
    font-size: 4rem;
    margin-bottom: 30px;
}

.security-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.security-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.security-card {
    background-color: #dce6f5;
    padding: 25px;
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    transition: transform 0.3s ease;
}

.security-card:hover {
    transform: scale(1.03);
}

.security-icon {
    font-size: 2rem;
    color: var(--purple);
}

.light-icon{
    margin-right: 15px ;
    margin-top: -5px;
    color: yellow;
}

.security-card p {
    font-size: 1.2rem;
    margin: 0;
    color: var(--text-color);
}


.security-image{
    flex: 1;
    text-align: center;
}

.security-image img {
    max-width: 60%;
    border-radius: 10px;
    height: 20%;
}

.security-footer {
    background-color: var(--purple);
    color: white;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 1.2rem;
}

.table{
    font-size: 1.5rem;
    text-align: center;
    border: 1px #dce6f5;
}


.questions-title{
    font-size: 1.2rem;
}

.list-security{
    font-weight: bold;
}


.bullets{
    font-size: 1.2rem;
}

.list-security{
    font-size: 1.4rem;
}

@media (max-width: 1128px) {
    


    .security-card {
        max-width: 100%;
    }

    .security-title{
        font-size: 2rem;
    }

    .security-card p{
        font-size: 1rem;
        margin-top: 20px;
    }

    .security-footer{
        font-size: 1rem;
    }

    .table{
        font-size: 1.2rem;
    }
    
 
    
}


@media (max-width: 900px) {
    .security-icon{
        font-size: 4rem;
    }

    .questions-title{
        font-size: 0.8rem;
    }
}


@media (max-width: 768px) {
    .security-image img{
        max-width: 100%;
    }


    .table{
        font-size: 0.6rem;
        text-decoration: none;
    }
    
    h4{
        font-size: 0.9rem;
    }

    .bullets{
        font-size: 0.8rem;
    }

    .list-security{
        font-size: 0.9rem;
    }

    .security-icon {
        font-size: 7rem;
        margin-top: -25px;
    }

}