@import '../css/App.css';

.header{
    justify-content: space-between;
    border-bottom: 1px solid #000433;
  }
  
.logo{
    margin-left: 50px;
}

.search-bar{
  margin-left: 50px;
  width: 30%;
  align-items: center;
}

.btn{
  padding: 5px;
  margin-left: 10px;
}


.nav-link{
  color: var(--purple) !important;
  font-size: 1rem;
}


.navbar-nav{
  margin-right: 50px;
}



.custom-dropdown{
    background-color: var(--beige) !important;
    text-align: left;
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
}

.custom-dropdown:hover{
  transform: scale(1.02);
}

.custom-dropdown a {
  color: var(--purple) !important;
  transition: 0.7s ease;
}

.custom-dropdown a:hover{
  background-color: var(--move);
}

/* Show dropdown menu on hover */
.nav-item.dropdown:hover .dropdown-menu {
  display: block; /* Ensures the dropdown is visible on hover */
  margin-top: 0; /* Adjusts any potential offset */
}

.dropdown-menu {
  display: none; /* Default state to hide dropdown */
  position: absolute; /* Ensures it appears below the parent */
  background-color: var(--beige); /* Matches your design */
  padding: 10px 0;
  border-radius: 5px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  transition: opacity 0.3s ease; /* Smooth show/hide animation */
}

.dropdown-menu a {
  color: var(--purple); /* Customize link color */
  padding: 10px 15px; /* Adds spacing for dropdown items */
  display: block; /* Ensures full width clickable area */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}

.dropdown-menu a:hover {
  background-color: var(--move); /* Highlight background on hover */
}

.dropdown-menu {
  opacity: 0; /* Initially hidden */
  transform: translateY(-10px); /* Moves up slightly */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth animation */
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Moves into place */
}


.search-suggestions {
  position: absolute;
  top: 70%;
  background: white;
  border: 1px solid #ddd;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 40%;
  z-index: 1000;
}

.search-suggestions li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.search-suggestions li:hover {
  background: #f0f0f0;
}


.first-suggestion {
  background-color: #e4ecf9;
  font-weight: bold; 
}
/* ISO Certification Badge - Positioned in the White Space */
.iso-badge {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #ffdd00, #ffcc00); /* Elegant Gold Gradient */
  padding: 6px 12px;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-left: 15px; /* Space between search bar and badge */
}

/* Hover effect for interaction */
.iso-badge:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* ISO Image */
.iso-img {
  width: 35px; /* Adjust for visibility */
  height: auto;
  margin-right: 8px;
}

/* Certification Text */
.iso-text {
  font-size: 0.85rem;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
}




/* Responsive Adjustments */
@media (max-width: 768px) {
  .iso-badge {
    padding: 6px 10px;
    font-size: 0.75rem;
  }
  .iso-img {
    width: 35px;
  }
}



@media (max-width: 1068px) {

  .nav-item{
    margin-left: 5px;
    font-size: 0.6rem;
    justify-content: center;
  }

  .navbar-nav{
    margin-left: 30px !important;
    margin-right: 0px !important;
  }

  .nav-link{
    font-size: 0.8rem;
  }

  .navbar-toggler{
    margin-right: 40px;
    font-size: 1rem;
}

  .logo img{
    width: 100px;
  }

  .search-bar{
    width: 30%;
  }

  .search-bar input{
    font-size: 0.5rem;
  }

  .search-bar button{
    font-size: 0.7rem;
  }

  .custom-dropdown{
    font-size: 0.7rem;
  }
}


@media (max-width: 1408px) {
    .nav-item{
        font-size: 0.3rem;
    }

    .logo img{
        width: 120px;
    }

    .nav-link{
      font-size: 0.75rem;
    }

    .iso-badge{
      padding: 4px 4px;
    }

}

@media only screen and (min-width: 500px) and (max-width: 1324px) { 
  .custom-dropdown{
    width: 30%;
    font-size: 0.7rem;
  }
}

/* ✅ Medium Screens (Tablets) */
@media (max-width: 1068px) {
  .iso-badge {
    padding: 4px 8px;
    max-width: 150px;
  }
  .iso-img {
    width: 30px;
  }
  .iso-text {
    font-size: 0.7rem;
  }
}

/* ✅ Smaller Screens (Mobile Phones) */
@media (max-width: 768px) {
  .iso-badge {
    padding: 3px 6px;
    max-width: 120px;
  }
  .iso-img {
    width: 25px;
  }
  .iso-text {
    font-size: 0.6rem;
  }
}

/* ✅ Extra Small Screens (Very Small Devices) */
@media (max-width: 500px) {
  .iso-badge {
    padding: 2px 4px;
    max-width: 100px;
  }
  .iso-img {
    width: 20px;
  }
  .iso-text {
    font-size: 0.5rem;
  }
}