@import '../css/App.css';

/* Main container */
.carousel-cards-container {
  background-color: #e4ecf9;
  padding: 30px;
}

/* Carousel Section */
.carousel-section {
  /* background-color: var(--purple);  */
  position: relative;
  height: 550px;
  overflow: hidden;
}

.c1{
  background-image: url('../images/c2.jpg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}

.c2 {
  background-image: url('../images/c5.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 

}

.c3 {
  background-image: url('../images/c3.jpg');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 

}

.c4 {
  background-image: url('../images/c4.jpg');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 

}

.c5 {
  background-image: url('../images/iso_gold.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; 
}

.carousel-content {
  padding: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.carousel-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
}

.carousel-content p {
  font-size: 1.2rem;
  color: #ffff;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;

}





.author {
  font-size: 1rem;
  font-style: italic;
}

.parag-big {
  padding: 22px;
}
/* Cards Section */
.cards-section {
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0px;
}

.custom-card {
  width: 80%;
  margin: 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
  /* background: linear-gradient(135deg, #000440, #004aad); */
  background-image: url('../images/pic4.jpg');
  border-radius: 50px;
  border: none;
  padding: 60px;
  color: white;
  text-align: center;
  transition: 0.7s ease !important;
}

.custom-card:hover {
  transform: scale(1.1);
}

.lire-plus{
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid white;
  color: var(--move);
  transition: all 0.3s ease-in-out;
  margin-top: -20px;
  font-weight: bold;
}

.lire-plus:hover{
  background-color: var(--move);
  color: #000440;
  transform: scale(1.05);
}

/* Media Queries */

@media (max-width: 1408px) {
  .custom-card {
    font-size: 0.8rem;
  }

  
  
}

@media (max-width: 1208px) {
  .custom-card {
    font-size: 0.8rem;
  }

  .carousel-content{
    padding: 100px;
  }
  
}


@media (max-width: 768px) {
  .carousel-content {
    justify-content: center;
    text-justify: auto;
    padding: 50px;
  }

  .carousel-content h2 {
    font-size: 1rem;
  }
  
  
  .carousel-content p {
    font-size: 0.9rem; 
    text-align: justify;
  }

  /* Reduce carousel arrow size */
  .carousel-control-prev,
  .carousel-control-next {
    font-size: 1rem !important; 
    width: 20px; 
  }

}


