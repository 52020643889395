.itcloud-container {
    padding: 50px;
    background-color: #f1f6fc;
  }
  
  .itcloud-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;
    flex-wrap: wrap;
  }
  
  .itcloud-description {
    width: 60%;
    padding-right: 20px;
  }
  
  .itcloud-description ul {
    list-style: none;
    padding: 0;
  }
  
  .itcloud-description ul li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
    font-size: 1.2rem;
    padding: 15px;
    background-color: var(--purple);
    color: #f1f6fc;
    border-radius: 5px;
    transition: all 0.3s;
}
  
  .itcloud-description ul li:hover {
    transform: translateX(5px);
    
}
  
  .itcloud-description ul li:before {
    content: "✔";
    color: var(--beige);
    font-weight: bold;
    margin-right: 2px;    
}
  
  .itcloud-image {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .itcloud-details {
    margin: 40px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .details-list li {
    margin: 15px 0;
    font-size: 1.2rem;
  }
  
  .itcloud-advantages {
    margin-top: 50px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .itcloud-advantages h2 {
    text-align: center;
    color: var(--purple);
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .advantage-card {
    flex: 1;
    background-color: var(--move);
    padding: 20px;
    margin: 10px;
    text-align: center;
    border-radius: 10px;
    transition: transform 0.7s ease, box-shadow 0.3s ease;
  }
  
  .advantage-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  .advantage-card h3 {
    color: var(--purple);
    margin-bottom: 10px;
  }
  

  @media (max-width: 768px) {
    .itcloud-content {
      flex-direction: column; 
      align-items: center; 
      text-align: center; 
    }
  
    .itcloud-description {
      width: 100%; 
      padding-right: 0; 
    }
  
    .itcloud-image {
      width: 100%; 
      margin-top: 20px; 
    }
  
    .responsive-image {
      max-width: 80%; 
    }
  }