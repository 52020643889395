@import '../css/App.css';

.footer-container {
    background-color: #000433;
    color: #ffffff;
    font-size: 0.9rem;
  }
  
  .footer-logo {
    width: 150px;
    margin-bottom: 15px;
    height: 100px;
  }
  
  .footer-container h5 {
    color: var(--beige);
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .footer-container p, 
  .footer-container a {
    color: #ffffff;
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  .footer-container a {
    text-decoration: none;
  }
  
  .footer-container a:hover {
    color: #f1f6e1;
  }
  
  .footer-bottom {
    color: #ffffff;
    font-size: 0.8rem;
    margin-top: 20px;
  }
  
  .footer-social-icons a {
    color: #ffffff;
    font-size: 1.5rem;
    margin-right: 10px;
    transition: color 0.3s ease;
  }
  
  .footer-social-icons a:hover {
    color: #f1f6e1;
  }
  
  .footer-container ul {
    padding: 0;
    list-style: none;
  }
  
  .footer-container ul li {
    margin-bottom: 8px;
  }
  
  .footer-container ul li a {
    color: #ffffff;
  }
  
.iso-certification-footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.iso-img-footer {
  width: 200px; 
  height: auto;
  margin-bottom: 10px;
}

.iso-button-footer {
  display: inline-block;
  padding: 10px 14px;
  background-color: #0056b3;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.iso-button-footer:hover {
  background-color: #003c80;
}
