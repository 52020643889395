:root {
  --purple: #000433;
  --beige: #f1f6e1;
  --move: #e4ecf9;
}

.bg-purple {
  background-color: var(--purple) !important;
}

.bg-beige {
  background-color: var(--beige) !important;
}


* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


.main-container {
  position: relative;
  color: #e4ecf9;
  text-align: center;
  padding: 10px 20px;
  min-height: 85vh;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden; 
}

.main-container::before {
  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--bg-image); 
  filter: blur(3px); 
  z-index: 1; 
}


.main-content {
  max-width: 60%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}


.main-title{
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 50px;
}

.main-parag {
  font-size: 1.4rem;
  margin-bottom: 40px;
  line-height: 1.6;
  padding: 5px;
}


.main-button {
  display: inline-block;
  color: #000433;
  background-color: white;

  border: 2px solid white;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  margin-top: 34px;
}

.main-button:hover {
  background-color: #e4ecf9;

  color: #000433;
  transform: scale(1.05);

}

.cta-back {
  background-color: var(--move);
  width: 100%;
}

.arrow-container {
  position: absolute;
  bottom: 150px;
  right: 150px; 
  z-index: 2;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 40px;
  padding-top: 10px;
}

.arrow {
  width: 40px;
  height: 40px;
  border-right: 3px solid #e4ecf9;
  border-bottom: 3px solid #e4ecf9;
  transform: rotate(45deg); 
  animation: arrow-bounce 1.5s infinite ease-in-out;
}

@keyframes arrow-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(45deg);
  }
  40% {
    transform: translateY(10px) rotate(45deg);
  }
  60% {
    transform: translateY(5px) rotate(45deg);
  }
}



@media (max-width: 1428px){
  .main-title {
    font-size: 1.4rem;
  }



  .main-parag{
    font-size: 0.9rem;
    width: 120%;
    margin-left: -50px;
    justify-content: left;
  }


  .main-button{
    font-size: 0.7rem;
  }

  .arrow{
    width: 20px;
    height: 20px;
  }

  .arrow-container{
    padding: 20px;
    bottom: 50px;
  }
}


@media (max-width: 1128px){
  .main-title {
    font-size: 1rem;
  }



  .main-parag{
    font-size: 0.7rem;
    width: 120%;
    margin-left: -50px;
    justify-content: left;
  }


  .main-button{
    font-size: 0.7rem;
  }

  .arrow-container{
    padding: 25px;
    bottom: 50px;
  }
}

@media (max-width: 908px){

  .arrow-container{
    padding: 20px;
    bottom: 50px;
  }
}


@media (max-width: 768px) {
  .main-title {
    font-size: 1rem;
  }



  .main-parag{
    font-size: 0.7rem;
    width: 120%;
    margin-left: -20px;
    justify-content: left;
  }


  .main-button{
    font-size: 0.7rem;
  }

  .arrow-container {
    position: absolute;
    bottom: 50px;
    right: 40px; 
    z-index: 2;
    cursor: pointer;
    padding: 15px;
  }
}


@media (max-width: 468px) {
  .arrow-container{
    padding: 10px;
  }

  
}

