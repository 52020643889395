@import '../css/App.css';

.structures-container {
    padding: 40px;
    background-color: var(--move);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  
  .structures-title {
    color: #000433;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
    flex: none;
    align-self: none;
    align-items: center;
  }
  
  .structure-card {
    
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.7s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 80px;
    height: 100%;
    text-decoration: none;
  }
  
  .card-1{
    background-image: url('../images/pic4.jpg');
    background-size: cover;
  }

  .card-2{
    background-image: url('../images/card1.jpg');
    background-size: cover;
  }

  .card-3{
    background-image: url('../images/card2.png');
    background-size: cover;
  }

  .card-4{
    background-image: url('../images/card3.png');
    background-size: cover;
  }




  .structure-card:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .icon-container {
    background-color: #e4ecf9;
    border-radius: 50%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .structure-icon {
    font-size: 3rem;
    color: var(--purple);
  }
  
  .structure-card h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--beige);
    margin-bottom: 10px;
  }
  
  .structure-card p {
    font-size: 1rem;
    line-height: 1.5;
    color: #fff;
    margin-top: 20px;
    text-align: center;
  }


  @media (max-width: 1400px) {
    .structures-container {
        padding: 20px;
        flex-direction: column;
    }
}
  @media (max-width: 768px) {
    .structures-title {
      font-size: 1.5rem;
    }
  
    .structure-card p {
      font-size: 0.85rem;
    }
  }
  