

.sectors {
    background-color: #f1f6fe;
}


.sectors-container {
    padding: 40px 20px; 
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto; 
  }
  
  /* Sector Title */
  .sectors-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #000433;
    margin-bottom: 30px; 
  }
  
  /* Sector Card */
  .sector-card {
    background-color: #e4ecf9;
    margin: 0 auto;
    border-radius: 10px;
    padding: 40px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .sector-card h5 {
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 2px 4px;
}

  
  .sector-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Style */
  .sector-icon {
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px;
    border-radius: 5px;
  }
  

  .sector-1{
    background-image: url("../images/img2.jpg");
    background-size: cover;

  }

  .sector-2{
    background-image: url("https://img.freepik.com/vecteurs-libre/abstrait-technologie-securisee_23-2148331624.jpg?ga=GA1.1.1432589437.1731449602&semt=ais_hybrid");
    background-size: cover;
    background-blend-mode: multiply;
    

  }

  .sector-3{
    background-image: url("../images/img3.png");
    background-size: cover;

  }

  
  .sector-4{
    background-image: url("https://img.freepik.com/vecteurs-libre/communication-numerique-fond-technologie-reseautage-mondial_53876-119502.jpg?ga=GA1.1.1432589437.1731449602&semt=ais_hybrid");
    background-size: cover;

  }
  
  .sector-5{
    background-image: url("../images/img4.png");
    background-size: cover;
  }
  
  .sector-6{
    background-image: url("../images/img5.png");
    background-size: cover;
  }
  
  .sector-7{
    background-image: url("../images/img6.jpeg");
    background-size: cover;
  }

  
  .sector-8{
    background-image: url("../images/img1.jpg");
    background-size: cover;
  }

  
  @media (max-width: 768px) {
    .sector-icon {
      font-size: 2rem;
    }
    .sectors-title {
        font-size: 1.4rem;
    }
  
    .sector-card h5 {
      font-size: 1rem;
    }
  }
  