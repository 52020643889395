.industries-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background: #fafafa;
  }
  
  .industries-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .industries-icon {
    font-size: 3rem;
    color: #000440;
    margin-bottom: 15px;
    animation: bounce 2s infinite;
  }

  .industries-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin: 30px 0;
  }


  .industry-img {
    max-height: 300px; 
    width: 100%; 
    margin-top: 20px; 
    border-radius: 10px; 
    object-fit: cover;
    object-position: center; 
    display: block; 
    margin-left: auto;
    margin-right: auto;
  }


  .industries-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .industries-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-icon {
    font-size: 2.5rem;
    color: #000440;
    margin-bottom: 15px;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  .footer-note {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .footer-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    background: #004aad;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    transition: background 0.3s ease;
  }
  
  .footer-button:hover {
    background: #00337a;
  }


  
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .industries-title {
    font-size: 2rem;
    font-weight: 700;
    color: #000440;
  }
  
  .industries-subtitle {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
  }
  
  .industries-content {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .industries-section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 20px;
  }
  
  .industries-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .industries-list li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
  }

  .industries-introduction {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .industries-text {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .industries-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
  }
  
  .industries-list li {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  
  .industries-footer {
    text-align: center;
    margin-top: 30px;
  }

  .industries-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
  }
  
  .benefit-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.33% - 20px); 
    min-width: 280px; 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .benefit-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 10px;
  }
  
  .benefit-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  
  .footer-note {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .footer-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    background: #004aad;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    transition: background 0.3s ease;
  }
  
  .footer-button:hover {
    background: #00337a;
  }
  

  /* Responsive Design */
@media (max-width: 1024px) {
    .benefit-card {
      max-width: 45%; /* Two cards per row */
    }
  }
  
  @media (max-width: 768px) {
    .benefit-card {
      max-width: 90%; /* One card per row */
    }
  
    .section-title {
      font-size: 1.2rem; /* Adjust title for smaller screens */
    }
  
    .industries-text {
      font-size: 0.9rem; /* Adjust text for smaller screens */
    }
  }