.services-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 50px 20px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background: #fafafa;
  }
  
  .services-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .services-icon {
    font-size: 3rem;
    color: #000440;
    margin-bottom: 15px;
    animation: bounce 2s infinite;
  }
  
  .services-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    margin: 30px 0;
  }
  
  .services-img {
    max-height: 300px;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .services-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 250px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .services-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-icon {
    font-size: 2.5rem;
    color: #000440;
    margin-bottom: 15px;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  .services-footer {
    text-align: center;
    margin-top: 30px;
  }
  
  .services-title {
    font-size: 2rem;
    font-weight: 700;
    color: #000440;
  }
  
  .services-subtitle {
    font-size: 1.2rem;
    color: #555;
    margin-top: 10px;
  }
  
  .services-content {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .services-section {
    margin-bottom: 30px;
  }
  
  .services-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .services-list li {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .services-introduction {
    margin-bottom: 30px;
    text-align: left;
  }
  
  .services-text {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .services-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
  }
  
  .benefit-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.33% - 20px);
    min-width: 280px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .benefit-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #000440;
    margin-bottom: 10px;
  }
  
  .benefit-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .benefit-card {
      max-width: 45%; 
    }
  }
  
  @media (max-width: 768px) {
    .benefit-card {
      max-width: 90%; 
    }
  
    .section-title {
      font-size: 1.2rem;
    }
  
    .services-text {
      font-size: 0.9rem; 
    }
  }
  
  