

/* General Styles */
.participations-container {
    font-family: 'Poppins', sans-serif;
    color: #333;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Hero Section */
  .hero-section-participations {
    background: url('../images/participations.jpg') center/cover no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    
  }

  .hero-section-conseil {
    background: url('../images/conseil.jpg') center/cover no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    
  }

  .hero-section-technologies {
    background: url('../images/tech.jpg') center/cover no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    
  }

  .hero-section-securite {
    background: url('../images/securite.jpg') center/cover no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    
  }

  .hero-title {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .hero-description {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  .about-section {
    background: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    max-width: 100%;
  }
  
  .about-text {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    width: 85%;
  }
  
  /* Process Timeline Section */
  .process-timeline-section {
    display: flex;
    background: #f5faff;
    padding: 40px;
    border-radius: 12px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    

  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .timeline-step {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .icon-circle {
    background: #000043;
    color: #fff;
    font-size: 2rem;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .step-content {
    flex: 1;
  }
  
  .step-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Testimonials Section */
  .testimonials-section {
    margin: 40px 0;
    text-align: center;
    margin-bottom: 5%;
  }
  
  .testimonials blockquote {
    font-size: 1.2rem;
    font-style: italic;
    margin: 20px auto;
    max-width: 600px;
    position: relative;
    color: #555;
  }
  
  .testimonials blockquote::before {
    content: open-quote;
    font-size: 2rem;
    color: #004aad;
    position: absolute;
    left: -10px;
    top: -10px;
  }
  
  .testimonials footer {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #777;
  }



  /*TEchnolgies PArt*/

/* Solutions Flow Section */
.solutions-flow-section {
  padding: 40px 20px;
  background: #f9f9f9;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #004aad;
  margin-bottom: 20px;
}

.flow-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

.flow-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.flow-step {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.flow-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.flow-icon {
  font-size: 3rem;
  color: #004aad;
  margin-bottom: 15px;
}

.flow-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000440;
  margin-bottom: 10px;
}

.flow-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

.flow-arrow {
  font-size: 2rem;
  color: #004aad;
  font-weight: bold;
  margin: 0 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .flow-container {
    flex-direction: column;
  }

  .flow-arrow {
    display: none;
  }
}

.flow-step {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none; /* Remove link underline */
  color: inherit; /* Inherit text color */
  cursor: pointer;
}

.flow-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.flow-step .flow-icon {
  font-size: 3rem;
  color: #000043;
  margin-bottom: 15px;
}

.flow-step .flow-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000440;
  margin-bottom: 10px;
}

.flow-step .flow-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

.flow-arrow {
  font-size: 2rem;
  color: #004aad;
  font-weight: bold;
  margin: 0 10px;
}


  .commitment-section {
    padding: 40px 20px;
    background: #f5faff;
    text-align: center;
  }
  
  .commitment-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
  }
  
  .commitment-pillars {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .pillar {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pillar:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .pillar-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000043;
    margin-bottom: 10px;
  }
  
  .pillar-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }


/*securité*/
  @media (max-width: 768px) {
    .pillar {
      max-width: 100%;
    }
  }
  
  
  
.circular-section {
  padding: 40px 20px;
  background: #f5faff;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #000043;
  margin-bottom: 20px;
}

.circular-text {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #555;
}

/* Circular Grid Styles */
.circular-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.circular-item {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circular-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.circle-icon {
  background: #e3f2fd;
  color: #000043;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 10px;
}

.circle-title {
  font-size: 1rem;
  font-weight: bold;
  color: #000440;
  margin-bottom: 5px;
}

.circle-description {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Responsive Design */
@media (max-width: 768px) {
  .circular-item {
    width: 180px;
    height: 180px;
    padding: 15px;
  }

  .circle-icon {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }

  .circle-title {
    font-size: 0.9rem;
  }

  .circle-description {
    font-size: 0.8rem;
  }
}


.orchestration-section {
  padding: 40px 20px;
  background: #f5faff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.orchestration-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
  width: 70%;
}

.orchestration-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.orchestration-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.orchestration-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.orchestration-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000043;
  margin-bottom: 10px;
}

.orchestration-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .orchestration-item {
    max-width: 100%;
  }

  .about-text{
    font-size: 1rem;
    width: 300px;
  }
}
