@import '../css/App.css';


.why-title {
    color: #000433;
    font-size: 2rem;
    font-weight: 700;
}

.safari-values-container {
  position: relative;
  margin: 0 auto;
  max-width: 95%;
  background-color: var(--move);
}

.value-item {
  margin-bottom: 35px; 
  position: relative;
  margin-top: 20px;
}

.circle-container {
  display: flex;
  align-items: flex-start; 
  gap: 15px; 
}

.diagonal-img {
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  width: 100%;
  height: auto;
  display: block;
}

.circle {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #000440, #004aad);
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  flex-shrink: 0; 
}

.text-container {
  background-color: #f5f5dc; 
  padding: 10px 20px;
  border-radius: 8px; 
}

.text-container h5 {
  font-size: 1.2rem;
  margin-bottom: 5px;
  font-weight: bold;
}

.text-container p {
  font-size: 1rem;
  margin: 0;
}


@media(max-width: 992px) {

  .value-item{
    top: 0;
    left: 0;
  }

  .diagonal-img{
    clip-path: none;
  }
  
}

@media (max-width: 768px) {
  .circle {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    line-height: 40px;
  }

  .text-container {
    padding: 8px 15px;
  }

  .text-container h5 {
    font-size: 1rem;
  }

  .text-container p {
    font-size: 0.9rem;
  }

  .diagonal-img{
    clip-path: none;
  }

  .value-item{
    top: 0;
    left: 0;
    margin-left: 50px;
  }
}

@media (max-width: 480px) {
  .circle {
    width: 35px;
    height: 35px;
    font-size: 1rem;
    line-height: 35px;
  }

  .text-container {
    padding: 5px 10px;
  }

  .text-container h5 {
    font-size: 0.9rem;
  }

  .text-container p {
    font-size: 0.8rem;
  }
}
