@import '../css/App.css';



.contact-form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  
  }
  
  .contact-form h2 {
    font-size: 1.8rem;
    color: #000433;
  }
  
  .contact-form .form-group {
    margin-bottom: 15px;
  }
  
  .contact-form .form-group label {
    font-weight: bold;
    color: #000433;
  }
  
  .contact-form .form-control {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .contact-form .btn {
    background-color: #000433;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .contact-form .btn:hover {
    background-color: var(--move);
    color: #000433;
  }
  