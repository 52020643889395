.card {
    border-radius: 10px;
    transition: transform 0.2s;
    /*background: linear-gradient(135deg, #000440, #004aad);*/

  }

  .card-details {
    border-radius: 10px;
    /*background: linear-gradient(135deg, #000440, #004aad);*/

  }
  
  .card:hover {
    transform: scale(1.03);
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-subtitle {
    font-size: 1rem;
  }
  
  .job-salary{
    color: #004aad !important;
  }
  .text-primary {
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .voir-btn{
    background-color: var(--move);
    border-radius: 5px;
    padding: 5px;
    transition: all 0.3s ease;
  }

  .voir-btn:hover{
    background-color: #004aad;
    color: aliceblue;
  }

  .job-desc{
    font-weight: 600;
    font-size: 1.4rem;
  }

  .info-item {
    background-color: #f8f9fa;
    padding: 8px 16px;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }



  
  .markdown-content ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  
  .markdown-content h1 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
  
  .markdown-content p {
    margin-bottom: 1rem;
  }
  
 

  .job-toast{
    top: 20;
    right: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }