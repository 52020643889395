@import '../css/App.css';



.four-buttons-section {
    background-color: #e4ecf9; 
    padding: 20px;
    border-radius: 10px;
  }
  
  .info-card {
    background-image: url('../images/card1.jpg');
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .info-card h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000433;
  }
  
  .info-card p {
    font-size: 1rem;
    color: #000433;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .info-card h5 {
      font-size: 1rem;
    }

    .info-card{
        padding: 10px;
    }

  
    .info-card p {
      font-size: 0.7rem;
    }
  }
  