.systems-container {
    padding: 50px;
    background-color: #f9fafc;
  }
  
  .systems-title {
    font-size: 3rem;
    color: var(--purple);
    text-align: center;
    margin-bottom: 20px;
  }
  
  .systems-content {
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
  }
  
  .systems-intro {
    font-size: 1.2rem;
    padding: 20px;
    background-color: var(--move);
    border-left: 4px solid var(--purple);
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .systems-list {
    margin: 20px 0;
  }
  
  .systems-list h2 {
    font-size: 2rem;
    color: var(--purple);
    margin-bottom: 10px;
  }
  
  .systems-list ul {
    padding-left: 20px;
    margin-left: 20px;
  }
  
  .systems-list li {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .systems-technologies {
    background-color: #eef4ff;
    padding: 20px;
    border-radius: 10px;
    font-size: 1.2rem;
  }
  
  .systems-technologies h2 {
    font-size: 2rem;
    color: var(--purple);
    margin-bottom: 10px;
  }
  

  
  .systems-values {
    background-color: #eef4ff;
    padding: 20px;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .systems-values h2 {
    color: var(--purple);
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .systems-values ul {
    padding-left: 20px;
  }
  
  .systems-values li {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .systems-values p {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  
  .systems-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .systems-table th,
  .systems-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .systems-table th {
    background-color: var(--purple);
    color: white;
  }
  
  .systems-table td {
    background-color: #f9fafc;
  }
  
  .systems-card {
    background: linear-gradient(135deg, #000440, #004aad);
    color: white !important;
    border: none;
    padding: 10px 20px;
    font-size: 1.3rem;
    border-radius: 30px;
    margin-bottom: 20px;
    cursor: default;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .contact-btn{
    background-color: var(--move);
    margin-top: -50px !important;
  }

  @media (max-width: 768px) {
    .systems-title {
      font-size: 2rem;
      margin-top: 20px;
    }
  
    .systems-intro {
      font-size: 1rem;
    }
  
    .systems-list ul {
      padding-left: 10px;
    }
  
    .systems-technologies {
      font-size: 1rem;
      padding: 10px;
    }

    .systems-values {
        font-size: 1rem;
        padding: 10px;
      }

    .systems-container{
        padding: 20px;
    }

  }

