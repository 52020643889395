.terms-container {
    padding: 50px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .terms-title {
    text-align: center;
    color: var(--purple);
    margin-bottom: 30px;
    font-size: 2.5rem;
  }
  
  .terms-content {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
    background-color: var(--move);
    padding: 1px 25px 25px 10px;
    border-radius: 15px;
  }
  
  .terms-content h2 {
    margin-top: 30px;
    font-size: 1.8rem;
    color: var(--purple);
  }
  
  .terms-content p {
    margin: 15px 0;
    font-size: 1.1rem;
  }
  
  .terms-content a {
    text-decoration: none;
  }
  
  .terms-content a:hover {
    text-decoration: underline;
  }
  