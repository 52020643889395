.ceo-message-container {
    display: flex;
    justify-content: center;
    padding: 40px;
    background-color: #f4faff;
  }
  
  .ceo-message-content {
    display: flex;
    align-items: flex-start;
    background-color: #dbe9ff;
    padding: 30px;
    border-radius: 15px;
    max-width: 1200px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ceo-image img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ceo-text {
    flex: 1;
  }
  
  .ceo-text h2 {
    font-size: 2.2rem;
    color: #000433;
    margin-bottom: 25px;
  }
  
  .ceo-text p {
    font-size: 1.1rem;
    color: #000433;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .ceo-text strong {
    font-size: 1.1rem;
    color: #000433;
  }
  
  .ceo-signature {
    margin-top: 20px;
    font-family: 'Cursive', sans-serif;
    color: #000433;
  }
  
  .ceo-signature p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .ceo-signature span {
    font-size: 0.9rem;
    color: #5a5a5a;
  }
  
  .ceo-linkedin a {
    margin-top: 10px;
    display: inline-block;
    font-size: 1.5rem;
    color: #0e76a8;
    text-decoration: none;
  }
  
  .ceo-linkedin a:hover {
    color: #064a7d;
  }


  .btn-link {
    border: none;
    background: none;
    color: #0e76a8;
    font-weight: bold;
    cursor: pointer;
    padding: 0;
  }
  
  .btn-link:hover {
    text-decoration: underline;
    color: #064a7d;
  }
  
  

  @media (max-width: 1086px) {
    .ceo-message-container{
        padding: 20px;
    }
  }

  @media (max-width: 706px) {
    .ceo-message-container{
        width: 100%;
    }

    .ceo-image img{
        height: 60px;
        width: 60px;
    }

    .ceo-text h2 {
        font-size: 1.2rem;
    }

    .ceo-text p{
        font-size: 0.6rem;
    }

    .ceo-text ul {
        font-size: 0.5rem;
    }

    .ceo-message-content {
        padding: 15px;
    }

    .ceo-text {
        margin-left: -10px;
    }
  }