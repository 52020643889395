.company-swiper-container {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    margin: 20px auto;
    text-align: center;
    max-width: 1600px;
  }
  
  .swiper-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #000433;
    margin-bottom: 20px;
  }
  
  .mySwiper {
    padding: 20px 10px;
    margin-top: 7%;
    align-items: center;
  }
  
  .company-logo {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    transition: transform 0.3s ease;
  }
  
  .company-logo:hover {
    transform: scale(1.1);
  }
  
  /* Hide Swiper navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  display: none !important;
}

/* Media queries for responsiveness */
@media (max-width: 968px) {
    .company-logo {
      max-width: 70%; /* Increase logo size on tablets */
    }
  }

@media (max-width: 768px) {
  .company-logo {
    max-width: 80%; /* Increase logo size on tablets */
  }
}

@media (max-width: 480px) {
  .company-logo {
    max-width: 70%; /* Increase logo size on smartphones */
  }
}
