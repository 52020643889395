@import '../css/App.css';




.network-container{
    padding: 50px;
    background-color: #f1f6fc;
}


.network-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    color: var(--purple);
    font-size: 4rem;
}


.network-intro{
    font-size: 1.5rem;
    padding: 20px 60px 20px 40px;
    background-color: #FAF9F6;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    margin-bottom: 2%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.network-intro:hover{
    transform: translateY(-5px);
}

.network-container h2{
    padding: 20px;
    
    background: linear-gradient(135deg, #000440, #004aad);
    color: white;
    border-radius: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    background-image: url("../images/network.jpg");
}

.content{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  
    padding: 30px 30px 20px 0px ;
    background-color: white;
    border-radius: 10px;
}



.network-card {
    padding: 20px;
    background-color: var(--move);
    box-shadow: 10px 20px 18px  rgba(0, 0, 0, 0.1); 
    transition: transform 0.7s ease, box-shadow 0.3s ease;
    /*display: flex;
    flex-direction: column;
    */
    border: 1px var(--purple) solid;
    color: var(--purple);
    border-radius: 10px;
    margin-left: 35px;
    
}

.network-card h3{
    justify-content: left;
    font-size: 2.5rem;
}


.network-card p{
    justify-content: left;
    font-size: 1.4rem;
    margin-top: 25px;
}

.network-card:hover{
    transform: scale(1.05);
}



.card2 {
    background-color:#1767ad;
}


.card2 h3{
    color: #f1f6fc;
    
}
.card2 p{
    color: white;
}


.card3 {
    margin-top: 25%;
    background-color: #000440;
    color: white;
    margin-bottom: 25px;
}

.network-image img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    margin-bottom: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


@media (max-width: 1428px){
    .network-card h3{
        font-size: 1.7rem;
    }

    .network-card p{
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .network-title{
        font-size: 2rem;
    }

    .network-intro{
        font-size: 1rem;
    }
    

    .card2{
        margin-top: 25px;
    }

    .card3{
        margin-top: 25px;
    }

    .content{
        margin-top: 15px;
    }

    .network-card {
        padding: 30px;
        width: 100%;
    }
    .network-card h3{
        font-size: 1.2rem;
    }

    .network-card p{
        font-size: 0.8rem;
        padding-right: 0;
    }

}

@media (max-width: 468px){
    .network-intro{
        padding: 20px;
        font-size: 1rem;
    }
} 
